import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { Button } from "../../primitives";

export default function SignOut() {
  async function onLogout() {
    await signOut();
    // Redirect on SignOut
    window.location.href = "/";
  }
  return (
    <Button className="w-min" variant={"ghost"} onClick={onLogout}>
      Logout
    </Button>
  );
}
