type Props = {
  track: Track;
};

const AdaptiveSongTitle = ({ track }: Props) => {
  return (
    <>
      {track.creators && track.creators.length > 1 ? (
        track.creators.map((creator, index) => (
          <div className="inline text-wrap min-w-max  truncate">
            <p className="inline mr-1 text-wrap ">{creator.name}</p>
            {index !== track.creators.length - 1 ? (
              <p className="inline mr-1">,</p>
            ) : null}
          </div>
        ))
      ) : (
        <p className="inline-block">
          {track.creators ? track.creators[0].name : null}
        </p>
      )}
    </>
  );
};

export default AdaptiveSongTitle;
