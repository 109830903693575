import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context";
import UpdateAlbumModal from "../../pages/Profile/components/CreatorDashboard/components/UpdateAlbumModal";
import UpdateTrackModal from "../../pages/Profile/components/CreatorDashboard/components/UpdateTrackModal";
import Album from "../Album";

export default function AlbumList(props) {
  const stagedive = useContext(ApiContext);
  const [album, setAlbum] = useState({} as any);
  type Tag = {
    value: string;
    label: string;
  };
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [track, setTrack] = useState({ tags: [] } as any);
  const [albums, setAlbums] = useState(props.albums);
  const [showUpdateAlbumModal, setShowUpdateAlbumModal] = useState(false);
  const [showUpdateTrackModal, setShowUpdateTrackModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [albumError, setAlbumError] = useState(null as any);
  const [trackError, setTrackError] = useState(null as any);

  useEffect(() => {
    setAlbums(props.albums);
  }, [props.albums]);

  const updateAlbum = async (event, album) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const albumDTO = new FormData(event.target) as any;
      album.creators.forEach((creator) =>
        albumDTO.append("creatorIds", creator.id)
      );

      if (albumDTO.get("name") === "") {
        // TODO should use validation lib here or pass validation errors from backend
        alert("Album name cannot be empty");
        throw new Error("Album name cannot be empty");
      }

      if (albumDTO.get("albumId") === "Choose an album") {
        alert("Please select an album");
        throw new Error("Please select an album");
      }

      const fileSize = (albumDTO.get("imageFile") as any).size;
      if (fileSize === 0) {
        albumDTO.delete("imageFile");
      } else {
        if (fileSize > 5_000_000) {
          const errorMessage = `File size too large (${Math.ceil(fileSize / 1_000_000)}mb). Please upload a file less than 5mb.`;

          alert(errorMessage);
          throw new Error(errorMessage);
        }
      }

      const newAlbum = await stagedive.updateAlbum(album.id, albumDTO);
      const updatedAlbums = albums.map((album: any) => {
        if (album.id === newAlbum.id) {
          return newAlbum;
        } else {
          return album;
        }
      });

      setAlbums(updatedAlbums);
      setShowUpdateAlbumModal(false);
      setAlbumError(null);
    } catch (error: any) {
      console.error(error);
      setAlbumError(`Unable to update album: ${error.toString()}`);
    }

    setIsLoading(false);
  };

  const updateTrack = async (event, track) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const trackDTO = new FormData(event.target) as any;
      if (trackDTO.get("name") === "") {
        // TODO should use validation lib here or pass validation errors from backend
        alert("Track name cannot be empty");
        throw Error("Track name cannot be empty");
      }

      const fileSize = (trackDTO.get("audioFile") as any).size;
      if (fileSize === 0) {
        trackDTO.delete("audioFile");
      } else {
        if (fileSize > 200_000_000) {
          alert(
            `File size too large (${Math.ceil(fileSize / 1_000_000)}mb). Please upload a file less than 200mb.`
          );
          return false;
        }
      }

      if (trackDTO.get("previewRange") === "") {
        trackDTO.set("previewRange", "[0,30]");
      }

      //Removes the old list of tags
      trackDTO.delete("tagIds");

      //Maps in the new selected tags
      const tagIds = selectedTags.map((tag) => tag.value);
      tagIds.forEach((tagId) => {
        trackDTO.append("tagIds", tagId);
      });

      const updatedTrack = await stagedive.updateTrack(track.id, trackDTO);
      if (track.album.id !== updatedTrack.album.id) {
        // Move track to other album
        const updatedAlbums = albums
          .map((album: any) => {
            let updatedTracks = album.tracks;
            if (album.id === track.album.id) {
              // remove updated track from original album
              updatedTracks = album.tracks.filter(
                (track) => track.id !== updatedTrack.id
              );
            }
            return { ...album, tracks: updatedTracks };
          })
          .map((album: any) => {
            let updatedTracks = album.tracks;
            if (album.id === updatedTrack.album.id) {
              // add to new album
              updatedTracks = album.tracks.concat([updatedTrack]);
            }
            return { ...album, tracks: updatedTracks };
          });
        setAlbums(updatedAlbums);
      } else {
        // Update track within it's current album
        const updatedAlbums = albums.map((album: any) => {
          const updatedTracks = album.tracks.map((track) => {
            if (track.id === updatedTrack.id) return updatedTrack;
            else return track;
          });
          return { ...album, tracks: updatedTracks };
        });

        setAlbums(updatedAlbums);
      }
      setShowUpdateTrackModal(false);
      setTrackError(null);
    } catch (error: any) {
      console.error(error);
      setTrackError(`Unable to update track: ${error.toString()}`);
    }

    setIsLoading(false);
  };

  const deleteTrack = async (event, track) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (window.confirm("Are you sure you want to delete this track?")) {
        await stagedive.deleteTrack(track.id);

        const updatedAlbums = albums.map((album: any) => {
          const updatedTracks = album.tracks.filter(
            (albumTrack) => albumTrack.id !== track.id
          );
          return { ...album, tracks: updatedTracks };
        });

        setAlbums(updatedAlbums);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const toggleUpdateAlbumModal = (album) => {
    setAlbum(album);
    setShowUpdateAlbumModal(!showUpdateAlbumModal);
  };

  const toggleUpdateTrackModal = (track) => {
    setTrack(track);
    const tagIds = track.tags.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setSelectedTags(tagIds);
    setShowUpdateTrackModal(!showUpdateTrackModal);
  };

  const handleTagSelectChange = (selectedTags) => {
    setSelectedTags(selectedTags);
    //const tagIds = selectedTags.map(tag => tag.value);
    //setTrack({ ...track, tags: tagIds });
    //setSelectedTags(selectedTags);
  };

  return (
    <div>
      {albums.length > 0 ? (
        albums.map((album, index) => (
          <Album
            album={album}
            onAlbumEdit={toggleUpdateAlbumModal}
            onTrackEdit={toggleUpdateTrackModal}
            onTrackDelete={deleteTrack}
            editable={true}
            key={index}
          ></Album>
        ))
      ) : (
        <p className="text-center">No albums created yet</p>
      )}
      <UpdateAlbumModal
        album={album}
        open={showUpdateAlbumModal}
        handleSubmit={updateAlbum}
        handleToggle={setShowUpdateAlbumModal}
        isLoading={isLoading}
        error={albumError}
      />
      <UpdateTrackModal
        track={track}
        albums={albums}
        open={showUpdateTrackModal}
        handleSubmit={updateTrack}
        handleToggle={setShowUpdateTrackModal}
        isLoading={isLoading}
        error={trackError}
        selectedTags={selectedTags}
        handleTagSelectChange={handleTagSelectChange}
      />
    </div>
  );
}
