import MusicPlayerFooter from "components/MusicPlayerFooter/MusicPlayerFooter";
import { getWebsiteDomain } from "config";
import { usePlaylistContext } from "context/NewPlaylistContext";
import { usePlayerContext } from "context/PlayerContext";
import { usePlaylistPlayer } from "context/playlistPlayer";
import { useGetAlbum, useGetTrack, useGetTracksByTags } from "hooks";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useUserStore } from "store";
import { doesSessionExist } from "supertokens-auth-react/recipe/session";
import placeholder from "../../assets/images/stagedive-logo.png";
import { SmallCardCarousel } from "../../components/MusicSections";
import "./index.css";

export default function TrackView() {
  const { user } = useUserStore();

  const { playNewTrack } = usePlayerContext();
  const { setPlaylistData } = usePlaylistContext();
  const { clearAndLoadNewPlaylist } = usePlaylistPlayer();

  const [sessionExists, setSessionExists] = useState(false);

  //Uses params to pass in the album id
  const { id } = useParams();

  const { data: track, isLoading } = useGetTrack(id);
  const { data: album } = useGetAlbum(track?.album.id);

  const trackTags = track?.tags.map((tag) => tag.name).join(",");

  const { data: similarTracks = [] } = useGetTracksByTags({
    tags: trackTags ? trackTags : "Rock",
    count: 10,
    offset: 0,
    sort: "-createdAt",
  });

  const [showLinkCopied, setShowLinkCopied] = useState(false);

  const copyURL = () => {
    try {
      const copyText = `${getWebsiteDomain()}/track/${id}`;
      navigator.clipboard.writeText(copyText);
      setShowLinkCopied(true);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLinkCopied(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [showLinkCopied]);

  useEffect(() => {
    async function loadTrackWhenNoSession() {
      const sessionExists = await doesSessionExist();

      setSessionExists(sessionExists);
      // if unauthenticated, play the track on page load
      if (!sessionExists && track) {
        playNewTrack(track);
        setPlaylistData([track]);
      }
    }

    loadTrackWhenNoSession();
  }, [track, sessionExists]);

  if (isLoading) {
    return (
      <span className="loading loading-bars loading-lg flex h-2/5 m-auto"></span>
    );
  }

  return (
    <>
      <div className="track-view-container" id="container">
        <div className="track-view-container left-side" id="track-container">
          <div className="text-center pb-3" id="track-header">
            <h1 className="scroll-m-20 text-primary pb-2 text-3xl font-semibold tracking-tight first:mt-0">
              {track?.name}
            </h1>
            <h2>{track?.album.creators[0].name}</h2>
          </div>

          <img
            src={track?.album.coverImageUrl ?? placeholder}
            alt="Album Art"
            className="w-full h-auto block mx-auto border-2 border-gray-300 rounded-lg shadow-md bg-black h-50 object-cover"
          />

          <div className="w-full flex justify-between items-center p-2" id="ctas">
            {track && (
              <span
                className="material-symbols-rounded play-playlist cursor-pointer text-3xl"
                onClick={() => clearAndLoadNewPlaylist([track], 0)}
              >
                play_circle
              </span>
            )}
            <div className={"ml-4 " + (showLinkCopied ? "tooltip tooltip-open" : "")} data-tip="Link Copied!">
              <button
                onClick={copyURL}
                className="p-2 bg-zinc-800 hover:bg-zinc-800 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="h-3 w-3 flex-no-shrink fill-current fill-white"
                >
                  <path d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="right-side">
          {sessionExists ? (
            <>
              <SmallCardCarousel
                title="More from this Artist"
                tracks={album ? album.tracks : []}
              />
              <SmallCardCarousel
                title="You might also like"
                tracks={similarTracks}
              />
            </>
          ) : (
            <div className="pt-10 text-center">
              <h2 className="text-primary text-2xl font-semibold">
                You're now listening to a preview of {track?.name}
              </h2>
              <p className="mt-2">Sign in to listen to more tracks</p>
              <Link to="/auth" className="btn mt-3">
                Sign In
              </Link>
            </div>
          )}
        </div>
      </div>
      {!sessionExists && <MusicPlayerFooter />}
    </>
  );
}
