import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePlaylistContext } from "context/NewPlaylistContext";
import { usePlayerContext } from "context/PlayerContext";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useScreen } from "usehooks-ts";
import { ApiContext } from "../../context";
import Modal from "../Modal";

const SearchBar = () => {
  const inputRef = useRef<any>(null);
  const resultsRef = useRef<any>(null);
  const stagedive = useContext(ApiContext);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [showModal, setShowModal] = useState(false);
  const screen = useScreen();

  const isMobile = () => {
    if (!screen) return false;
    return screen.width < 650;
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // @ts-ignore
        func.apply(this, args);
      }, delay);
    };
  };

  const handleChange = async (e) => {
    const searchTerm = e.target.value;
    if (!/\S/.test(searchTerm)) {
      setSearchResults([]);
    } else {
      try {
        const count = 7;
        const searchResults = await stagedive.search(searchTerm, count);
        setSearchResults(searchResults);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setSearchResults([]);
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      resultsRef.current &&
      !resultsRef.current.contains(event.target)
    ) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {isMobile() ? (
        <div className="ml-10">
          <FontAwesomeIcon
            icon={faMagnifyingGlass as IconProp}
            className="text-white text-lg cursor-pointer"
            onClick={() => setShowModal(true)}
          />
          <Modal
            open={showModal}
            backgroundColor="bg-neutral-900"
            onClose={() => setShowModal(!showModal)}
            parentClass="h-[100vh]"
          >
            <div className="h-screen">
              <SearchInput
                onChange={debounce(handleChange, 300)}
                searchResults={searchResults}
                inputRef={inputRef}
                resultsRef={resultsRef}
                isMobile={isMobile()}
                handleClose={handleClose}
              />
            </div>
          </Modal>
        </div>
      ) : (
        <SearchInput
          onChange={debounce(handleChange, 300)}
          searchResults={searchResults}
          inputRef={inputRef}
          resultsRef={resultsRef}
          isMobile={isMobile()}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

const SearchInput = ({
  onChange,
  searchResults,
  inputRef,
  resultsRef,
  isMobile,
  handleClose,
}) => {
  const stagedive = useContext(ApiContext);
  const { playNewTrack } = usePlayerContext();
  const { setPlaylistData } = usePlaylistContext();

  return (
    <>
      <div className="relative">
        <input
          type="text"
          placeholder="Search..."
          className="bg-gray-800 focus:outline-none focus:border-gray-600 border rounded-lg py-2 px-4 block w-full leading-normal"
          onChange={onChange}
          ref={inputRef}
        />
        <div className="absolute inset-y-0 right-0 mr-1 md:mr-2 mt-1">
          <span className="material-symbols-rounded">arrow_right_alt</span>
        </div>

        {searchResults.length > 0 && (
          <ul
            ref={resultsRef}
            className="absolute p-1 px-1 bg-neutral-900 w-full rounded-lg list-none"
          >
            {searchResults.map(({ type, document: { name, id } }) => (
              <li
                className="transition duration-300 ease-in-out hover:bg-neutral-500 px-2 pt-2 pb-1 cursor-pointer"
                key={type + id}
                onClick={handleClose}
              >
                {type === "Album" ? (
                  <Link to={`/album/${id}`}>
                    <p className="my-0">{name}</p>
                    <p className="my-0 text-xs">{type}</p>
                  </Link>
                ) : type === "Creator" ? (
                  <Link to={`/creator/${id}`}>
                    <p className="my-0">{name}</p>
                    <p className="my-0 text-xs">{type}</p>
                  </Link>
                ) : (
                  <div>
                    <Link
                      to={`/track/${id}`}
                      onClick={async () => {
                        let track = await stagedive.getTrack(id);
                        playNewTrack(track);
                        setPlaylistData([track]);
                      }}
                    >
                      <p className="my-0">{name}</p>
                      <p className="my-0 text-xs">{type}</p>
                    </Link>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
        {isMobile && searchResults.length === 0 && (
          <p className="text-center mt-3">
            Search for tracks, albums, and creators
          </p>
        )}
      </div>
    </>
  );
};

export default SearchBar;
