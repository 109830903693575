import { useState } from "react";
import "./Faq.css"; // Import your CSS file for styling

const Faq = () => {
  const faqData = [
    {
      question: "How do payments work?",
      answer: (
        <>
          <p>
            StageDive operates on a subscription model where each listener pays
            a monthly fee of $3.99 USD. The unique aspect of our payment system
            is that 50% of this subscription fee is distributed among the
            creators based on the streaming time. The more seconds a listener
            spends streaming a creator's content, the higher the share of the
            subscription revenue that creator receives. This approach ensures a
            fair and performance-based compensation for our valued creators.
          </p>
        </>
      ),
    },
    {
      question: "What payment platform do you use to process payments?",
      answer: (
        <>
          <p>
            At StageDive, we process all payments using the highly secure and
            trusted platform, Stripe. This ensures the confidentiality and
            safety of your financial information, providing a seamless and
            reliable payment experience for our users.
          </p>
        </>
      ),
    },
    {
      question: "How often do payments occur?",
      answer: (
        <>
          <p>
            Payments from StageDive to creators occur on a monthly basis,
            precisely on the first day of each month.
          </p>
          <p>
            Subscriptions are automatically charged and renewed per your renewal
            schedule, providing a hassle-free experience for our users. It's
            important to note that this monthly cycle is also when we distribute
            the share of subscription revenue to the creators on our platform,
            ensuring a timely and consistent payout process.
          </p>
        </>
      ),
    },
    {
      question: "How much does a listener subscription cost?",
      answer: (
        <>
          <p>
            A listener subscription on StageDive is priced at $3.99 USD per
            month.
          </p>
          <p>
            It's important to note that any add-on features and optional tipping
            are separate from the subscription cost. The pricing for add-ons and
            tipping may vary from user to user based on their individual usage
            and preferences, allowing for a personalized experience on our
            platform.
          </p>
        </>
      ),
    },
    {
      question: "How much does a creator account cost?",
      answer: (
        <>
          <p>
            Creating an account on StageDive for creators is entirely{" "}
            <b>free</b>, encompassing basic functionality such as posting,
            sharing, and streaming their own content.
          </p>
          <p>
            However, if a creator desires the additional capability to listen to
            content from other artists on the platform, the same $3.99 listener
            subscription applies. This model ensures flexibility and
            accessibility for creators, allowing them to tailor their experience
            based on their preferences.
          </p>
        </>
      ),
    },
    {
      question: "What portion of my subscription is paid out to creators?",
      answer: (
        <>
          <p>
            A significant and fair 50% of your $3.99 subscription fee is
            allocated to creators on StageDive every month.
          </p>
          <p>
            This distribution is based on the amount of time you spent streaming
            the content of each artist within that specific month. This approach
            ensures that creators receive compensation in proportion to the
            engagement and support they receive from listeners. It's our way of
            recognizing and rewarding the talent and dedication of our valued
            artists.
          </p>
        </>
      ),
    },
    {
      question: "Why should I listen to music on StageDive?",
      answer: (
        <>
          <p>
            Listening to music on StageDive offers an exceptional ad-free
            experience. Your subscription not only ensures an uninterrupted
            listening experience but also directly supports the artists you
            stream. StageDive is committed to empowering independent creators by
            allowing them to receive direct support from their fans and
            listeners. By choosing to listen on StageDive, you are not just
            enjoying music; you're actively contributing to the artistic
            community, making it the best place to show support for the art and
            expression we all enjoy.
          </p>
        </>
      ),
    },
    {
      question: "Why should I consider posting my music/podcast to StageDive?",
      answer: (
        <>
          <p>
            Consider posting your music or podcast on StageDive because it's the
            platform where you will receive direct support from your listeners.
            By encouraging your audience to use StageDive, you will start
            receiving a portion of each listener's monthly subscription,
            providing a sustainable income for your creative endeavors.
          </p>
          <p>
            We are actively building a community that values music and creative
            expression, and StageDive serves as a powerful tool to drive your
            growth as a creator. Join us in shaping a community that appreciates
            and supports the art you create.
          </p>
        </>
      ),
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq accordion-container text-white">
      <h1>StageDive FAQ</h1>
      {faqData.map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${activeIndex === index ? "active" : ""}`}
        >
          <div
            className="faq-question"
            onClick={() => handleAccordionClick(index)}
          >
            <span className="plus-minus-icon">
              {activeIndex === index ? "-" : "+"}
            </span>
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer">{faq.answer}</div>
          )}
        </div>
      ))}
    </section>
  );
};

export default Faq;
