import { usePlaylistPlayer } from "context/playlistPlayer";
import placeholder from "../../assets/images/stagedive-logo.png";

const Album = ({
  album,
  onAlbumEdit,
  onTrackEdit,
  onTrackDelete,
  editable = false,
}) => {
  const { clearAndLoadNewPlaylist } = usePlaylistPlayer();

  return (
    <div className="bg-neutral-900 rounded-md mb-6 p-6 overflow-x-auto">
      <div className="flex">
        <img
          className="flex-none w-14 h-14 mr-6 object-contain"
          alt="album cover"
          src={album.coverImageUrl ? album.coverImageUrl : placeholder}
        />
        <h3 className="text-1xl font-bold pt-5 inline-flex items-center">
          {album.name}
          <span
            className="material-symbols-rounded play-playlist cursor-pointer text-2xl ml-2"
            onClick={() => clearAndLoadNewPlaylist(album.tracks, 0)}
          >
            play_circle
          </span>
        </h3>
        {editable && (
          <button
            className="btn ml-auto normal-case"
            onClick={() => onAlbumEdit(album)}
          >
            Edit
          </button>
        )}
      </div>
      <div className="mt-4">
        <div className="flex font-bold mb-3 pl-3">
          <div className="w-12">#</div>
          <div className="flex-1">Track</div>
          <div className="w-12"></div>
          {editable && <div className="w-24"></div>}
        </div>
        {album.tracks.length > 0 ? (
          album.tracks.map((track, index) => (
            <div
              className="flex items-center hover:bg-neutral-800 mb-3 pl-3 h-12 rounded-sm"
              key={track.id}
            >
              <div className="w-12">{index + 1}</div>
              <div className="flex-1">{track.name}</div>
              <div className="w-12">
                <span
                  className="material-symbols-rounded play-playlist cursor-pointer"
                  onClick={() => clearAndLoadNewPlaylist(album.tracks, index)}
                >
                  play_circle
                </span>
              </div>
              {editable && (
                <div>
                  <button
                    className="btn btn-s normal-case m-1 hover:bg-neutral-700"
                    onClick={() => onTrackEdit({ ...track, album })}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-s normal-case m-1 hover:bg-neutral-700"
                    onClick={(e) => onTrackDelete(e, track)}
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="text-center">No tracks added yet</div>
        )}
      </div>
    </div>
  );
};

export default Album;
