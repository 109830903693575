import { useEffect, useState } from "react";

const PreviewRangeInput = ({ initialRange = "[0,30)", onChange }) => {
  // Parse initial range from [start,end] format
  const parseRange = (rangeStr: string) => {
    if (!rangeStr) return [0, 30];
    const matches = rangeStr.match(/(\d+),(\d+)/);
    if (!matches) return [0, 30];
    return [parseInt(matches[1]), parseInt(matches[2])];
  };

  const [range, setRange] = useState(parseRange(initialRange));

  useEffect(() => {
    setRange(parseRange(initialRange));
  }, [initialRange]);

  const updateRange = (index, value) => {
    const newRange = [...range];
    newRange[index] = Math.max(0, value); // Prevent negative values
    onChange(newRange);
    setRange(newRange);
  };

  return (
    <div className="flex items-center space-x-2">
      <button
        className="p-2 text-white"
        onClick={(e) => {
          e.preventDefault();
          updateRange(0, range[0] - 10);
        }}
      >
        <p>-</p>
      </button>

      <button
        className="p-2 text-white"
        onClick={(e) => {
          e.preventDefault();
          updateRange(0, range[0] + 10);
        }}
      >
        <p>+</p>
      </button>

      <div className="flex items-center border rounded px-3 py-2 bg-black">
        <input
          type="number"
          value={range[0]}
          onChange={(e) => updateRange(0, parseInt(e?.target?.value) || 0)}
          className="w-16 text-center focus:outline-none"
        />
        <span className="text-white mx-1"> to </span>
        <input
          type="number"
          value={range[1]}
          onChange={(e) => updateRange(1, parseInt(e?.target?.value) || 0)}
          className="w-16 text-center focus:outline-none"
        />
      </div>

      <button
        className="p-2 text-white"
        onClick={(e) => {
          e.preventDefault();
          updateRange(1, range[1] - 10);
        }}
      >
        <p>-</p>
      </button>

      <button
        className="p-2 text-white"
        onClick={(e) => {
          e.preventDefault();
          updateRange(1, range[1] + 10);
        }}
      >
        <p>+</p>
      </button>
    </div>
  );
};

export default PreviewRangeInput;
