import React from "react";

interface SocialProofProps {
  name: string;
  imageUrl: string;
  url: string;
  description: string;
}

const SocialProoof: React.FC<SocialProofProps> = ({
  name,
  imageUrl,
  url,
  description,
}) => {
  const handleClick = () => {
    window.location.href = url;
  };
  return (
    <div className="mb-10">
      <h3 className="text-xl m-2">{name}</h3>
      <img
        src={imageUrl}
        alt={`Testimony by ${name}`}
        onClick={handleClick}
        style={{ cursor: "pointer" }}
        className="w-48 mb-5 rounded-sm"
      />
      <p>{description}</p>
    </div>
  );
};

export default SocialProoof;
