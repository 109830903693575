import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBell,
  faEnvelope,
  faEnvelopeOpen,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useDeleteUserNotification,
  useGetUserNotifications,
  useUpdateUserNotification,
} from "hooks";
import { useOnClickOutside } from "usehooks-ts";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useUserStore } from "store";

export default function NotificationsDropdown() {
  const { user } = useUserStore();
  const [isOpen, setIsOpen] = useState(false);
  const observerTarget = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } =
    useGetUserNotifications({
      userId: user?.id,
    });

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  // Watches when the bottom of the dropdown is visible. If so, fetch more notifications
  useEffect(() => {
    const element = observerTarget.current;
    if (!element) return;

    const option = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.disconnect();
  }, [handleObserver]);

  // Closes the dropdown when clicking outside
  useOnClickOutside(dropdownRef, () => {
    if (isOpen) setIsOpen(false);
  });

  const hasUnreadNotifications = () => {
    if (!data) return false;
    return data.pages.some((page) => page.items.some((notif) => !notif.read));
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 text-gray-600"
        aria-label="Notifications"
      >
        <div className="relative inline-block">
          <FontAwesomeIcon
            icon={faBell as IconProp}
            className={"text-white text-xl"}
          />
          {hasUnreadNotifications() && (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 rounded-full transform translate-x-1/2 -translate-y-1/2">
              •
            </span>
          )}
        </div>
      </button>
      {isOpen && (
        <div className="absolute -right-40 md:right-0 mt-2 md:w-110 bg-neutral-900 text-white rounded-md shadow-lg overflow-hidden z-10">
          <div className="py-2">
            <div className="max-h-96 overflow-y-auto">
              {status === "pending" ? (
                <span className="loading loading-spinner"></span>
              ) : status === "error" ? (
                <div className="p-4 text-center text-red-500">
                  Error fetching notifications
                </div>
              ) : (
                <>
                  {data.pages.map((page, i) => (
                    <React.Fragment key={i}>
                      {page.items.map((notification) => (
                        <NotificationListItem notification={notification} />
                      ))}
                    </React.Fragment>
                  ))}
                  <div ref={observerTarget} className="p-4 flex justify-center">
                    {isFetchingNextPage ? (
                      <div
                        className="loader"
                        aria-label="Loading more notifications"
                      ></div>
                    ) : hasNextPage ? (
                      <button
                        onClick={() => fetchNextPage()}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Load more
                      </button>
                    ) : (
                      <p className="text-sm">No more notifications</p>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const NotificationListItem = ({ notification }) => {
  const { mutate: updateUserNotification } = useUpdateUserNotification();
  const { mutate: deleteUserNotification } = useDeleteUserNotification();

  const relativeTime = (timestamp: Date, currentTime: Date) => {
    const diff = Math.floor(
      (currentTime.getTime() - timestamp.getTime()) / 1000
    ); // Convert to seconds

    if (diff < 60) {
      return "just now";
    } else if (diff < 3600) {
      return `${Math.floor(diff / 60)} minutes ago`;
    } else if (diff < 86400) {
      return `${Math.floor(diff / 3600)} hours ago`;
    } else {
      return `${Math.floor(diff / 86400)} days ago`;
    }
  };

  // TODO make `to` param in link dynamic
  return (
    <div
      key={notification.id}
      className={`flex justify-between gap-10 px-4 py-2 hover:bg-neutral-800   ${!notification.read && "bg-neutral-800"}`}
    >
      <div className="inline-block">
        <Link to={`/user/${notification.properties.userId}`}>
          <p className="text-sm hover:underline">{notification.content}</p>
        </Link>
        <p className="text-xs">
          {relativeTime(new Date(notification.createdAt), new Date())}
        </p>
      </div>
      <div className="flex flex-col gap-3 text-white ml-3">
        <div
          className="tooltip tooltip-bottom hover:cursor-pointer"
          data-tip={`Mark as ${notification.read ? "unread" : "read"}`}
          onClick={() => {
            updateUserNotification({
              userId: notification.userId,
              notificationId: notification.id,
              body: { read: !notification.read },
            });
          }}
        >
          {notification.read ? (
            <FontAwesomeIcon icon={faEnvelopeOpen as IconProp} />
          ) : (
            <FontAwesomeIcon icon={faEnvelope as IconProp} />
          )}
        </div>

        <div
          className="tooltip tooltip-bottom hover:cursor-pointer"
          data-tip="Delete"
          onClick={() => {
            deleteUserNotification({
              userId: notification.userId,
              notificationId: notification.id,
            });
          }}
        >
          <FontAwesomeIcon icon={faTrashCan as IconProp} />
        </div>
      </div>
    </div>
  );
};
