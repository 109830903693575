import { useState } from "react";
import "./SupportQuestions.css"; // Import your CSS file for styling

const SupportQuestions = () => {
  const faqData = [
    {
      question:
        "I can't see my Creator Dashboard. Can you provide more details on the issue or error message you're encountering?",
      answer:
        "If you're unable to see your Creator Dashboard, it's possible that you don't have an active creator account yet. To check, click the 'person' icon at the top right of the interface. If you don't see a 'Visit Creator Dashboard' button, look for a button to request a creator account. Please allow 5 business days for the review process. Once approved, you'll be granted access to your Creator Dashboard, where you can begin uploading tracks, album art, track tags, and more. This ensures a seamless experience for you to showcase and manage your content on StageDive.",
    },
    {
      question: "How do I create a playlist?",
      answer:
        "Creating a playlist on StageDive is easy. Simply click the blue icon containing a " +
        " in the player bar at the bottom of your screen. This will prompt an interface where you can choose to add the current track to an existing playlist or create a new playlist. This user-friendly feature allows you to curate and organize your favorite tracks seamlessly, enhancing your overall listening experience on the platform.",
    },
    {
      question: "How do I release a single?",
      answer:
        " Releasing a single on StageDive is straightforward. All tracks must exist within albums, and the process is similar to posting other albums on the platform. In essence, a single is just an album with one track included. When releasing a single, ensure to include album art and track tags. These features play a crucial role in presenting your content to interested listeners, enhancing discoverability and engagement with your music.",
    },
    {
      question: "How big can audio files be?",
      answer:
        "As of the publishing of this document, audio files uploaded to StageDive can be up to 200 MB in size. This provides creators with ample space to share high-quality audio content with our community. Keep this limit in mind while uploading your tracks to ensure a smooth and efficient process. If you encounter any issues or have specific requirements, please feel free to reach out to our support team for assistance.",
    },
    {
      question: "How big can album art images be?",
      answer:
        "As of the publishing of this document, album art images uploaded to StageDive can be up to 5 MB in size. This allows creators to showcase their work with high-quality visuals. When preparing your album art, ensure it meets this size limit for optimal presentation on the platform. If you have any questions or encounter issues, our support team is ready to assist you in the process of sharing your artistic vision with the StageDive community.",
    },
    {
      question: "What file formats are supported for album art?",
      answer:
        "Supported file formats include: ( png , jpeg , jpg , bmp , webp). This flexibility allows creators to use a range of common image formats to represent their albums visually. When preparing your album art, ensure it is in one of these supported formats to guarantee a smooth and visually appealing presentation on the platform. If you have any specific questions or encounter challenges, our support team is available to assist you throughout the process.",
    },
    {
      question: "What file formats are supported for audio files?",
      answer:
        "Supported file formats include: (mp3 , WAV , m4a , aac). This broad range of supported formats allows creators to upload their audio content in various commonly used formats. When sharing your music on StageDive, ensure your audio files are in one of these supported formats to ensure compatibility and a seamless listening experience for your audience. If you have any questions or encounter issues, our support team is here to assist you throughout the process.",
    },
    {
      question: "How do I contact Support?",
      answer:
        "- Support can be reached via the bug report feature on StageDive (the pink bug icon/ button). For direct messaging, you can reach out to @getstagedive on Twitter/ X. Response times may vary.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="support-questions accordion-container text-white">
      <h1>Support Questions</h1>
      {faqData.map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${activeIndex === index ? "active" : ""}`}
        >
          <div
            className="faq-question"
            onClick={() => handleAccordionClick(index)}
          >
            <span className="plus-minus-icon">
              {activeIndex === index ? "-" : "+"}
            </span>
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer">{faq.answer}</div>
          )}
        </div>
      ))}
    </section>
  );
};

export default SupportQuestions;
