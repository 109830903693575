import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUserStore } from "store";
import SignOut from "../../../../components/SignOut";
import { ApiContext } from "../../../../context";
import { Button, Separator } from "../../../../primitives";
import "../../Profile.css";
import DeleteAccountRequestModal from "./DeleteAccountRequestModal";
import RequestAccountDialog from "./RequestAccountDialog";

export default function ProfileInfo() {
  const stagedive = useContext(ApiContext);
  const { user } = useUserStore();
  const [requestingCreatorAccount, setRequestingCreatorAccount] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [creatorRequestStatus, setCreatorRequestStatus] = useState("");

  useEffect(() => {
    const getCreatorRequest = async () => {
      try {
        if (user) {
          const getCreatorResp = await stagedive.getUserCreatorRequests(
            user.id
          );
          if (getCreatorResp.length > 0) {
            setCreatorRequestStatus(getCreatorResp[0].status);
            setRequestingCreatorAccount(true); // Hide the button
          } else {
            setCreatorRequestStatus("No creator request found.");
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    getCreatorRequest();
  }, [user, stagedive]);

  const isCreator = (user) => {
    if (!user.roles) return false;
    else return user.roles.includes("creator");
  };

  const handleRequestCreatorAccount = () => {
    setShowModal(true);
  };

  return (
    <section className="p-10 h-full">
      <h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl">
        Account
      </h1>

      <div className="flex flex-col gap-4 mt-8">
        <div>
          Email: <p>{user?.email}</p>
        </div>

        {creatorRequestStatus && !isCreator(user) ? (
          <div className=" ">
            Creator Request Status: <p>{creatorRequestStatus}</p>
          </div>
        ) : null}
        <Separator />

        {!isCreator(user) && !requestingCreatorAccount ? (
          <RequestAccountDialog />
        ) : null}

        {isCreator(user) && requestingCreatorAccount ? (
          <Button
            className="w-min"
            onClick={handleRequestCreatorAccount}
            variant={"ghost"}
            disabled={requestingCreatorAccount}
          >
            Request Creator Account
          </Button>
        ) : null}

        <Link to="/auth/reset-password">
          <Button className=" w-min" variant={"ghost"}>
            Reset Password
          </Button>
        </Link>
        <SignOut />
      </div>

      <div className="flex flex-col items-end justify-end mt-[50%]">
        <DeleteAccountRequestModal />
      </div>
    </section>
  );
}
