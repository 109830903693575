// BrowseTag
import { usePlaylistPlayer } from "context/playlistPlayer";
import { useGetTracksByTags } from "hooks";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import placeholder from "../../../../assets/images/stagedive-logo.png";
import "./index.css";

function BrowseTag() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { clearAndLoadNewPlaylist } = usePlaylistPlayer();

  const [Tracks, setTracks] = useState<Track[]>([]);
  const [nextTracks, setNextTracks] = useState<Track[]>([]);

  //Call useGetTracksByTags once for current batch of tracks
  const { data: currentTracks = [] } = useGetTracksByTags({
    tags: id ?? "",
    count: 10,
    sort: "-createdAt",
    offset, // Current offset
  });

  //Call useGetTracksByTags for the next batch of tracks (to prefetch next page)
  const { data: upcomingTracks = [] } = useGetTracksByTags({
    tags: id ?? "",
    count: 10,
    sort: "-createdAt",
    offset: offset + 10,
  });

  useEffect(() => {
    setIsLoading(true);
    setTracks(currentTracks);
    setNextTracks(upcomingTracks);
    setIsLoading(false);
  }, [currentTracks, upcomingTracks, offset]);

  const nextPage = () => {
    if (nextTracks.length != 0) {
      setOffset(offset + 10);
      window.scrollTo(0, 0);
    }
    /* //Include better logic when total pages is exposed
        const { items } = this.props;
        const { currentPage } = this.state;
        const totalPages = Math.ceil(items.length / 10);
        if (currentPage < totalPages) {
            this.setState({ currentPage: currentPage + 1 });
        }
        */
  };

  const prevPage = () => {
    if (offset !== 0) {
      setOffset(offset - 10);
      window.scrollTo(0, 0);
    }
    /* //Include better logic when total pages is exposed
        const { currentPage } = this.state;
        if (currentPage > 1) {
            this.setState({ currentPage: currentPage - 1 });
        }
        */
  };

  return (
    <div className="mx-auto">
      <h1 className="text-primary py-10 text-3xl font-semibold text-center">
        <Link to={"./../"}>Browse </Link>/ {id}
        {Tracks.length > 0 ? (
          <span
            onClick={() => clearAndLoadNewPlaylist(Tracks, 0)}
            className="material-symbols-rounded play-playlist"
          >
            play_circle
          </span>
        ) : null}
      </h1>
      {isLoading && (
        <span className="loading loading-bars loading-lg flex h-2/5 m-auto"></span>
      )}
      {!isLoading && Tracks.length == 0 ? (
        <p className="text-center">
          Sorry, nothing to see yet.
          <br />
          More music coming soon!
        </p>
      ) : (
        <>
          <ul className="flex flex-row flex-wrap justify-center gap-5 max-w-screen-md">
            {Tracks.map((track, index) => (
              <li
                key={track.id}
                className="track-item w-80"
                onClick={() => clearAndLoadNewPlaylist(Tracks, index)}
              >
                <div className="albums-info">
                  <img
                    src={track.album.coverImageUrl ?? placeholder}
                    alt="Album Art"
                    className="object-contain bg-black"
                  />
                  <div className="songs-details">
                    {/* <img src={track.album.coverImageUrl ?? placeholder} alt="Album Art" className="object-contain bg-black"/>  */}
                    <span>
                      <p className="song-title text-primary">{track.name}</p>
                      <p className="song-artist text-primary">
                        {track.creators[0].name}
                      </p>
                      {/* {track.creators[0].name} - {track.name} */}
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="mx-auto py-5 text-center">
            {offset !== 0 && (
              <span
                className="material-symbols-rounded text-primary"
                onClick={prevPage}
              >
                arrow_back_ios
              </span>
            )}
            {nextTracks.length > 0 && (
              <span
                className="material-symbols-rounded text-primary"
                onClick={nextPage}
              >
                arrow_forward_ios
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default BrowseTag;
