import Modal from "components/Modal";
import { useCreateUserDeletionRequest, useGetMe } from "hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../primitives";

const DeleteAccountRequestModal = () => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { data: user } = useGetMe();
  const {
    mutate: createUserDeletionRequest,
    isPending,
    isError,
    error: submissionError,
  } = useCreateUserDeletionRequest();

  const toggleModal = () => {
    setOpen(!open);
    setReason("");
    setError("");
  };

  const handleSubmit = async () => {
    if (!user) {
      setError("User data is not available. Please try again later.");
      return;
    }

    createUserDeletionRequest({
      userId: (user as CurrentUser).id,
      userDeletionRequestDTO: {
        reason: reason.trim(),
      },
    });

    navigate("/deletion-request");

    if (!isError) {
      toggleModal();
    }
  };

  return (
    <>
      <Modal open={open} onClose={toggleModal}>
        <div className="p-4">
          <h1 className="text-2xl font-bold">Request Account Deletion</h1>
          <p className="mt-4">
            Are you sure you want to request to delete your account? Your
            account will be scheduled for deletion 15 days after submission.
          </p>
          <div className="mt-4">
            <label htmlFor="reason" className="block text-sm font-medium">
              (Optional) Reason for deletion:
            </label>
            <textarea
              id="reason"
              name="reason"
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={reason}
              onChange={(e) => setReason(e.target.value.slice(0, 2000))}
              maxLength={2000}
            />
            <p className="mt-2 text-sm text-gray-500">
              {reason.length}/2000 characters
            </p>
          </div>
          {(error || isError) && (
            <div className="mt-4">
              <p>
                {error ||
                  submissionError?.message ||
                  "An error occurred. Please try again."}
              </p>
            </div>
          )}
          <div className="flex justify-end mt-4">
            <Button
              onClick={toggleModal}
              className="mr-4"
              variant="ghost"
              size="sm"
            >
              Cancel
            </Button>
            <Button size="sm" disabled={isPending} onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>

      <Button className="w-min" onClick={toggleModal} variant="ghost">
        Delete Account
      </Button>
    </>
  );
};

export default DeleteAccountRequestModal;
