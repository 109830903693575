import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useErrorHandler } from "context/ErrorHandler";
import { useUserStore } from "store";
import { LibraryPlaylistCarousel } from "../../components/LibraryPlaylistCarousel";
import { ApiContext } from "../../context";
import { FollowingListModal } from "./components/FollowingListModal";
import UserBanner from "./components/UserBanner";
import UserEditProfileModal from "./components/UserEditProfileModal";

function UserView() {
  const { user: currentUser } = useUserStore();
  const { handleError } = useErrorHandler();
  const { id } = useParams();

  const stagedive = useContext(ApiContext);

  const [user, setUser] = useState({} as any);
  const [userPlaylists, setUserPlaylists] = useState<Playlist[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [disableModal, setDisableModal] = useState(false);
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [showFollowingModal, setShowFollowingModal] = useState(false);
  const [followedCreators, setFollowedCreators] = useState([] as any);

  const [editProfileError, setEditProfileError] = useState(null as any);

  const toggleEditProfileModal = () => {
    setOpenEditProfileModal((open) => !open);
  };

  const toggleFollowingModal = async () => {
    if (!currentUser) return;
    // get the list of users that the current user is following
    try {
      let followedCreators = await stagedive.getFollowedCreators(
        currentUser.id
      );

      followedCreators = followedCreators.map((creator) => ({
        ...creator,
        userIsFollowing: true,
      }));

      setFollowedCreators(followedCreators);
      setShowFollowingModal((open) => !open);
    } catch (error) {
      console.error(error);
    }
  };

  const updateUser = async (event) => {
    event.preventDefault();

    setDisableModal(true);
    try {
      const userDTO = new FormData(event.target);

      const profileImageFile = userDTO.get("profileImageFile") as File;
      if (profileImageFile && profileImageFile.size === 0) {
        userDTO.delete("profileImageFile");
      }

      if (profileImageFile.size > 5_000_000) {
        alert(
          `File size too large (${Math.ceil(profileImageFile.size / 1_000_000)}mb). Please upload a file less than 5mb.`
        );
        return false;
      }

      const updatedUser = await stagedive.updateUser(user.id, userDTO);
      setUser(updatedUser);
      setEditProfileError(null);
    } catch (error: any) {
      handleError(error);
      setEditProfileError(`Unable to update user: ${error.statusText}`);
    }

    setDisableModal(false);
    toggleEditProfileModal();
  };

  const handleFollowClick = async (creatorId) => {
    try {
      // find the creator in the list of followed creators
      const creator = followedCreators.find(
        (creator) => creator.id === creatorId
      );

      if (creator.userIsFollowing) {
        // unfollow
        await stagedive.addRemoveCreatorFollow(user.id, {
          creatorId: creator.id,
          action: "remove",
        });
        const updatedFollowedCreators = followedCreators.map((creator) => {
          if (creator.id === creatorId) creator.userIsFollowing = false;
          return creator;
        });
        setFollowedCreators(updatedFollowedCreators);
      } else {
        // follow
        await stagedive.addRemoveCreatorFollow(user.id, {
          creatorId: creator.id,
          action: "add",
        });
        const updatedFollowedCreators = followedCreators.map((creator) => {
          if (creator.id === creatorId) creator.userIsFollowing = true;
          return creator;
        });
        setFollowedCreators(updatedFollowedCreators);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    //Gets the data for the passed in user ID
    const getUserData = async (userId) => {
      setIsLoading(true);
      try {
        const user = await stagedive.getUser(userId);
        const userPlaylists = await stagedive.getUserPlaylists(userId);
        const publicPlaylists = userPlaylists.filter(
          (playlist) => playlist.public
        );
        setUser(user);
        setUserPlaylists(publicPlaylists);
      } catch (error) {
        userPlaylists;
        console.error(error);
      }
      setIsLoading(false);
    };

    getUserData(id);
  }, [id, stagedive, currentUser]);

  if (isLoading || !currentUser) {
    return (
      <span className="loading loading-bars loading-lg flex h-2/5 m-auto"></span>
    );
  } else
    return (
      <div>
        <UserBanner
          user={user}
          editable={currentUser.id === (user as any).id}
          onClickEditProfile={toggleEditProfileModal}
          showFollowingModal={toggleFollowingModal}
        />

        <section className="p-10 ml-1">
          <h2 className="text-4xl mb-2">Public Playlists</h2>
          {userPlaylists.map((playlist, index) => (
            <LibraryPlaylistCarousel
              playlistData={playlist}
              key={index}
              editable={false}
            />
          ))}
          {userPlaylists.length === 0 && <p>User has no public playlists</p>}
        </section>

        <FollowingListModal
          open={showFollowingModal}
          handleToggle={toggleFollowingModal}
          handleFollowClick={handleFollowClick}
          followList={followedCreators}
          showFollowButtons={currentUser.id === (user as any).id}
        />
        <UserEditProfileModal
          open={openEditProfileModal}
          user={user}
          handleSubmit={updateUser}
          handleToggle={toggleEditProfileModal}
          isLoading={disableModal}
          error={editProfileError}
        />
      </div>
    );
}

export default UserView;
