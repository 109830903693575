import { QueryClient } from "@tanstack/react-query";
import { Socket } from "phoenix";

export default class StagediveRTC {
  private user: CurrentUser;
  private queryClient: QueryClient;
  private socket: Socket | null;
  private userLobbyChannel: any | null;
  private userChannel: any | null;

  constructor(user, queryClient) {
    this.user = user;
    this.queryClient = queryClient;
    this.socket = null;
    this.userLobbyChannel = null;
    this.userChannel = null;
  }

  connect() {
    const userPayload = {
      id: this.user.id,
      displayName: this.user.displayName,
      bio: this.user.bio,
      profileImage: this.user.profileImage,
      followingCount: this.user.followingCount,
      email: this.user.email,
      roles: this.user.roles,
    };

    const socketUrl =
      process.env.REACT_APP_ENV === "prod"
        ? "wss://rtc.stagedive.com/socket"
        : "wss://rtc.dev.stagedive.com/socket";

    this.socket = new Socket(socketUrl, {});
    this.socket.connect();

    this.setupUserLobbyChannel(userPayload);
    this.setupUserChannel();
  }

  setupUserLobbyChannel(userPayload) {
    this.userLobbyChannel = this.socket.channel(`user:lobby`, userPayload);

    this.userLobbyChannel
      .join()
      .receive("ok", (resp) => {
        console.log("Joined userLobbyChannel successfully", resp);
      })
      .receive("error", (resp) => {
        console.log("Unable to join userLobbyChannel", resp);
      });
  }

  setupUserChannel() {
    this.userChannel = this.socket.channel(`user:${this.user.id}`, {});

    this.userChannel
      .join()
      .receive("ok", (resp) => {
        console.log("Joined userChannel successfully", resp);
      })
      .receive("error", (resp) => {
        console.log("Unable to join userChannel", resp);
      });

    this.userChannel.on("new_notification", () => {
      this.queryClient.invalidateQueries({
        queryKey: ["getUserNotifications", this.user.id, "-createdAt", 10],
      });
    });

    this.userChannel.on("creator_request_approved", () => {
      this.queryClient.invalidateQueries({
        queryKey: ["getMe"],
      });
    });
  }

  disconnect() {
    if (this.userLobbyChannel) {
      this.userLobbyChannel.leave();
    }
    if (this.userChannel) {
      this.userChannel.leave();
    }
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
